import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import styles from './Navbar.module.css';
import { fetchNavbarProps } from './../../functions/functions';
import { NavItem } from '@interfaces';

interface NavbarProps {
  initialNavItems?: NavItem[];
  onHamburgerToggle?: (isHamburger: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ initialNavItems = [], onHamburgerToggle }) => {
  const pathname = usePathname();
  const [navItems, setNavItems] = useState<NavItem[]>(initialNavItems);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [isHamburger, setIsHamburger] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const hamburgerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        const data = await fetchNavbarProps();
        setNavItems(data || []);
      } catch (error) {
        console.error('Failed to fetch nav items:', error);
        setNavItems([]);
      }
    };
    fetchNavItems();
  }, [initialNavItems]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (navRef.current && !navRef.current.contains(event.target as Node)) &&
        (hamburgerRef.current && !hamburgerRef.current.contains(event.target as Node))
      ) {
        setOpenDropdown(null);
        setIsHamburgerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current) navRef.current.classList.toggle(styles.scrolled, window.scrollY > 50);
      if (hamburgerRef.current) hamburgerRef.current.classList.toggle(styles.scrolled, window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;
  
    const updateLayout = () => {
      if (!navRef.current) return;
  
      // Calculate max width based on text content
      const dropButtons = navRef.current.querySelectorAll(`.${styles.dropBtn}`);
      let maxWidth = 0;
      dropButtons.forEach((btn) => {
        (btn as HTMLElement).style.width = 'auto';
        const width = btn.getBoundingClientRect().width;
        if (width > maxWidth) maxWidth = width;
      });
  
      const dropdowns = navRef.current.querySelectorAll(`.${styles.dropdown}`);
      dropdowns.forEach((dropdown) => {
        (dropdown as HTMLElement).style.width = `${maxWidth}px`;
        const btn = dropdown.querySelector(`.${styles.dropBtn}`);
        if (btn) (btn as HTMLElement).style.width = `${maxWidth}px`;
      });
  
      // Gather top positions for each dropdown
      const positions: number[] = [];
      dropdowns.forEach((dropdown) => {
        const rect = dropdown.getBoundingClientRect();
        positions.push(rect.top);
      });
  
      // Use a tolerance value (e.g., 5px) to count unique row positions.
      const tolerance = 5;
      const uniqueRowPositions: number[] = [];
      positions.forEach((pos) => {
        if (!uniqueRowPositions.some((uniquePos) => Math.abs(uniquePos - pos) < tolerance)) {
          uniqueRowPositions.push(pos);
        }
      });
      const uniqueRows = uniqueRowPositions.length;
  
      // Should be hamburger if more than 2 rows.
      const shouldBeHamburger = uniqueRows > 2;
  
      // Debug logging
      console.log('Positions:', positions);
      console.log('Unique Row Positions:', uniqueRowPositions);
      console.log('Unique Rows:', uniqueRows);
      console.log('Should Be Hamburger:', shouldBeHamburger);
  
      setIsHamburger(shouldBeHamburger);
      if (onHamburgerToggle) onHamburgerToggle(shouldBeHamburger);
      if (!shouldBeHamburger) {
        setIsHamburgerOpen(false);
        setOpenDropdown(null);
      }
    };
  
    const debouncedUpdateLayout = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(updateLayout, 100);
    };
  
    updateLayout();
    window.addEventListener('resize', debouncedUpdateLayout);
    return () => {
      window.removeEventListener('resize', debouncedUpdateLayout);
      clearTimeout(debounceTimer);
    };
  }, [navItems, onHamburgerToggle]);

  const isActive = (directory: string) => pathname.startsWith(`/${directory}`);
  const toggleDropdown = (directory: string) => {
    setOpenDropdown(openDropdown === directory ? null : directory);
  };
  const formatName = (name: string) =>
    name.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  const hamburgerMenu = (
    <div className={styles.hamburgerContainer} ref={hamburgerRef}>
      <button
        className={styles.hamburgerButton}
        onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
      >
        ☰
      </button>
      {isHamburgerOpen && (
        <nav className={styles.hamburgerMenu}>
          <div className={styles.navBarInner}>
            {navItems.map(({ directory, files }) => (
              <div key={directory} className={styles.dropdown}>
                <button
                  className={`${styles.dropBtn} ${isActive(directory) ? styles.active : ''}`}
                  onClick={() => toggleDropdown(directory)}
                >
                  {formatName(directory)}
                </button>
                <div
                  className={styles.dropdownContent}
                  style={{ display: openDropdown === directory ? 'block' : 'none' }}
                >
                  {files.map((file) => (
                    <Link
                      key={file}
                      href={`/${directory}/${file}`}
                      onClick={() => {
                        setOpenDropdown(null);
                        setIsHamburgerOpen(false);
                      }}
                    >
                      {formatName(file)}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </nav>
      )}
    </div>
  );

  const normalNavbar = (
    <nav className={styles.navContainer} ref={navRef}>
      <div className={styles.navBar}>
        <div className={styles.navBarInner}>
          {navItems.length === 0 ? (
            <div>Loading navigation...</div>
          ) : (
            navItems.map(({ directory, files }) => (
              <div key={directory} className={styles.dropdown}>
                <button
                  className={`${styles.dropBtn} ${isActive(directory) ? styles.active : ''}`}
                  onClick={() => toggleDropdown(directory)}
                >
                  {formatName(directory)}
                </button>
                <div
                  className={styles.dropdownContent}
                  style={{ display: openDropdown === directory ? 'block' : 'none' }}
                >
                  {files.map((file) => (
                    <Link key={file} href={`/${directory}/${file}`} onClick={() => setOpenDropdown(null)}>
                      {formatName(file)}
                    </Link>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </nav>
  );

  return <>{isHamburger ? hamburgerMenu : normalNavbar}</>;
};

export default Navbar;